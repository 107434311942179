import React, { Component } from "react";
import PropTypes from "react-proptypes";
import ContextConsumer from "../../Context";

export default class Faq extends Component {
  render() {
    const { onUpdate, items, post } = this.props;

    // TODO: FIX {marginBottom: '30px'}

    return (
      <ContextConsumer>
        {() => {

          return (
            <div>HI</div>
          )
        }}
      </ContextConsumer>
    );
  }
}

Faq.propTypes = {
  onUpdate: PropTypes.func,
};

Faq.defaultProps = {
  onUpdate: null,
};
