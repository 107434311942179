import React, { Component, Fragment } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Config from "../../data/SiteConfig";
import Success from "../components/Success";

export default class SuccessPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          title={[
            this.props.data.markdownRemark.frontmatter.title,
            Config.siteTitle
          ].join(" | ")}
        />

        <Success />
      </Fragment>
    );
  }
}

export const pageQuery = graphql`
  query SuccessPage {
    markdownRemark(fields: { slug: { eq: "/" } }) {
      html

      frontmatter {
        title
      }

      fields {
        slug
        post
      }
    }
  }
`;
